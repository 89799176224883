/*-------- Thumbnail -------*/
.thumbnail {
	display: block;
	padding: 4px;
	margin-block-end: 20px;
	line-height: 1.42857143;
	background-color: $custom-white;
	border: 1px solid $border-color;
	border-radius: 4px;
	@include trasition-background(transition, border, .2s, ease-in-out, moz webkit o ms);
	a>img, >img {
		margin-inline-end: auto;
		margin-inline-start: auto;
	}
	p:last-child {
		margin-block-end: 0;
	}
}
.thumbimg {
	height: 100%;
	width: 100%;
	display: block;
}
.thumbnail .caption {
	padding: 20px;
}
/*-------- Thumbnail -------*/