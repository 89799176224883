/*---- Image check -----*/
.imagecheck {
	margin: 0;
	position: relative;
	cursor: pointer;
}
.imagecheck-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.imagecheck-figure {
	border: 0px solid $border-color;
	border-radius: 5px;
	margin: 0;
	position: relative;
	height: 100%;
}
.imagecheck-input:checked~.imagecheck-figure {
	border-color: rgba(0, 40, 100, 0.24);
	border: 0 !important;
}
.imagecheck-figure:before {
	content: '';
	position: absolute;
	top: -20px;
	inset-inline-start: .25rem;
	display: block;
	width: 1.25rem;
	height: 1.25rem;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 1;
	border-radius: 5px;
	opacity: 0;
	transition: .3s opacity;
}
.imagecheck-input:checked~.imagecheck-figure:before {
	opacity: 1;
}
.imagecheck-image {
	max-width: 100%;
	opacity: .64;
	transition: .3s opacity;
	border-radius: 5px;
	&:first-child {
		border-start-start-radius: 5px;
		border-start-end-radius: 5px;
	}
	&:last-child {
		border-end-start-radius: 5px;
		border-end-end-radius: 5px;
	}
}
.imagecheck-input {
	&:checked~.imagecheck-figure .imagecheck-image, &:focus~.imagecheck-figure .imagecheck-image {
		opacity: 1;
	}
}
.imagecheck:hover .imagecheck-image {
	opacity: 1;
}
.imagecheck-caption {
	text-align: center;
	padding: .25rem .25rem;
	color: #cecde0;
	font-size: .875rem;
	transition: .3s color;
}
.imagecheck-input {
	&:checked~.imagecheck-figure .imagecheck-caption, &:focus~.imagecheck-figure .imagecheck-caption {
		color: #3d4e67;
	}
}
.imagecheck:hover .imagecheck-caption {
	color: #3d4e67;
}
/*---- Image check -----*/