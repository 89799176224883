/*-------- Modal -------*/
.modal-open {	
	#homeVideo.modal {
		background: rgba(25, 36, 79, 0.8);
		.modal-content {
			border: 10px solid $white;
			border-radius: 5px;
		}
	}
}
.modal-dialog-centered {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	min-height: calc(100% - (.5rem * 2));
}
.modal-backdrop {
	position: fixed;
	top: 0;
	inset-inline-end: 0;
	bottom: 0;
	inset-inline-start: 0;
	z-index: 1040;
	background-color: $black;
	&.fade {
		opacity: 0;
	}
	&.show {
		opacity: .5;
	}
}
.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}
.modal-header h4.modal-title {
	font-weight: $font-weight-semibold;
}
.modal-body label {
	font-weight: $font-weight-normal;
}
.inbox-body .modal .modal-body {
	input, textarea {
		border: 1px solid #e6e6e6;
		box-shadow: none;
	}
}
/*-------- Modal -------*/