/*-------- Custom -------*/
.login-img {
	background: url(../images/other/login.jpg);
	background-size: cover;
	width: 100%;
	height: 100%;
}
.error-img {
	background: url(../images/other/about.jpg);
	background-size: cover;
	width: 100%;
	height: 100%;
	.card {
		background: rgba($white, 0.2);
		box-shadow: 0 0.0625em 0.1875em 0 #076271, 0 0.5em 0 -0.25em rgba($white, 0.3), 0 0.5em 0.1875em -0.25em #086372, 0 1em 0 -0.5em rgba($white, 0.35), 0 1em 0.1875em -0.5em #096675;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: linear-gradient(to right, rgba(15, 117, 255, 0.8), rgba(45, 220, 211, 0.8));
		width: 100%;
		height: 100%;
		inset-inline-end: 0;
		top: 0;
	}
}
hr.divider {
	padding: 0;
	border: none;
	border-top: solid 1px $border-color;
	color: $text-color;
	text-align: center;
	margin: .5rem 0;
	&:after {
		content: "OR";
		display: block;
		position: relative;
		top: -1.5em;
		font-size: $default-value-size * 11;
		padding: 0 .25em;
		background: $custom-white;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		z-index: 9;
		inset-inline-start: 0;
		inset-inline-end: 0;
		line-height: 30px;
		font-weight: $font-weight-semibold;
		border: 1px solid $border-color;
		margin: 0 auto;
	}
}
/*-------- Custom -------*/