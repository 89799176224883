/*--------Badges -------*/
.badge {
    padding: 0.4rem ($space-xs * 3);
    font-size: 100%;
    font-weight: $font-weight-normal;
    border-radius: $default-value-size * 3;
	color: inherit;
}
.rounded-pill {
	padding-inline-end: 0.8rem;
	padding-inline-start: 0.8rem;
	border-radius: 10rem;
}
.badgetext {
	float: #{$float-right};
}
.btn .badge {
	position: relative;
	top: -1px;
}
/*-------- Badge Colors  -------*/
@each $colors, $value in $colors {
	@include badge-variant(".badge-#{$colors}", $value);}
/*--------Badge Colors -------*/

.bg-light {
	color: $text-color !important;
}
.bg-default {
	background: $color-light;
	color: #3d4e67;
}
/*-------- Badges -------*/
