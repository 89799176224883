/*-------- Tooltip-------*/
.tooltip {
	font-weight: $font-weight-normal;
	position: absolute;
	font-size: .875rem;
	z-index: 1070;
	@include tooltip-con();
	opacity: 0;
	&.show {
		opacity: .9;
	}
	.arrow {
		position: absolute;
		display: block;
		width: .8rem;
		height: .4rem;
		&::before {
			@include tooltip-before();
		}
	}
	&.tooltip-top{
		padding: 5px 0;
		margin-block-start: -3px;
		.tooltip-inner{
			&:before{
				bottom: 0;
				inset-inline-start: 50%;
				margin-inline-start: -5px;
				content: "";
				border-width: 5px 5px 0;
				border-top-color: $black;
			}
		}
	}
	&.tooltip-right{
		padding: 0 5px;
		margin-inline-start: 3px;
		.tooltip-inner{
			&:before{
				top: 50%;
				inset-inline-start: 0;
				margin-block-start: -5px;
				content: "";
				border-width: 5px 5px 5px 0;
				border-right-color: $black;
			}
		}
	}
	&.tooltip-bottom{
		padding: 5px 0;
		margin-block-start: 3px;
		.tooltip-inner{
			&:before{
				top: 0;
				inset-inline-start: 50%;
				margin-inline-start: -5px;
				content: "";
				border-width: 0 5px 5px;
				border-bottom-color: $black;
			}
		}
	}
	&.tooltip-left{
		padding: 0 5px;
		margin-inline-start: -3px;
		.tooltip-inner{
			&:before{
				top: 50%;
				inset-inline-end: 0;
				margin-block-start: -5px;
				content: "";
				border-width: 5px 0 5px 5px;
				border-left-color: $black;
			}
		}
	}
	&.bs-tether-element-attached-bottom {
		@extend .tooltip-top;
	}
	&.bs-tether-element-attached-left{
		@extend .tooltip-right;
	}
	&.bs-tether-element-attached-top{
		@extend .tooltip-bottom;
	}
	&.bs-tether-element-attached-right{
		@extend .tooltip-left;
	}
}
.bs-tooltip-top {
	padding: .4rem 0;	
	.arrow{	
		bottom: 0;
		&:before{
			top: 0;
			border-width: .4rem .4rem 0;
			border-top-color: $black;
		}
	}
}
.bs-tooltip-right {
	padding: 0 .4rem;	
	.arrow{	
		inset-inline-start: 0;
		width: .4rem;
		height: .8rem;
		&:before{
			inset-inline-end: 0;
			border-width: .4rem .4rem .4rem 0;
			border-right-color: $black;
		}
	}
}
.bs-tooltip-bottom {
	padding: .4rem 0;	
	.arrow{	
		top: 0;
		&:before{
			bottom: 0;
			border-width: 0 .4rem .4rem;
			border-bottom-color: $black;
		}
	}
}
.bs-tooltip-left {
	padding: 0 .4rem;	
	.arrow{	
		inset-inline-end: 0;
		width: .4rem;
		height: .8rem;
		&:before{
			top: 0;
			border-width: .4rem .4rem 0;
			border-top-color: $black;
		}
	}
}
.bs-tooltip-auto[x-placement^=top]{
	@extend .bs-tooltip-top;
}
.bs-tooltip-auto[x-placement^=right]{
	@extend .bs-tooltip-right;
}
.bs-tooltip-auto[x-placement^=bottom]{
	@extend .bs-tooltip-bottom;
}
.bs-tooltip-auto[x-placement^=left]{
	@extend .bs-tooltip-left;
}
.tooltip-inner {
	max-width: 200px;
	padding: .25rem .5rem;
	color: $white;
	text-align: center;
	background-color: $black;
	border-radius: ($default-value-size * 5);
	&:before{
		@include tooltip-before();
		width: 0;
		height: 0;
	}
}
.bd-example {
	position: relative;
	padding: 1rem;
	margin: 1rem -1rem;
	border-width: .2rem 0 0;
}
.bd-example-tooltip-static .tooltip {
	position: relative;
	display: inline-block;
	margin: 10px 20px;
	opacity: 1;
}
/*-------- Tooltip-------*/
