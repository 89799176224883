/*-------- Slide Carousel-------*/
.slide-carousel-indicators li {
	width: 20% !important;
	height: auto !important;
	padding: 20px;
	background: $black;
	border-radius: 0;
	border: 0 !important;
	&.active {
		width: 20% !important;
		height: auto !important;
		padding: 20px;
		background: $black;
		border-radius: 0;
		border: 0 !important;
	}
	&:before, &:after {
		display: none;
	}
}
#Slider {
	.slide-carousel-indicators {
		margin: 0;
		bottom: 0;
		li {
			position: relative;
			padding: 24px;
			width: 270px;
			height: auto;
			text-indent: 0;
			border-inline-start: 0;
			border-style: none none none solid;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			-ms-border-radius: 0;
			border-radius: 0;
			box-shadow: none;
			margin: 0 0;
			vertical-align: middle;
			background: rgba(66, 59, 156, 0.65);
			opacity: 1;
			border-inline-start: 1px solid rgba($white, 0.1) !important;
			&.active {
				position: relative;
				padding: 24px;
				width: 270px;
				height: auto;
				text-indent: 0;
				border-inline-start: 0;
				border-style: none none none solid;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				-ms-border-radius: 0;
				border-radius: 0;
				box-shadow: none;
				margin: 0 0;
				vertical-align: middle;
				background: rgba(66, 59, 156, 0.65);
				opacity: 1;
				border-inline-start: 1px solid rgba($white, 0.1) !important;
			}
		}
	}
	.carousel-item {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			inset-inline-start: 0;
			inset-inline-end: 0;
			display: block;
			z-index: 0;
			top: 0;
			background: $color-primary5 !important;
		}
	}
	.header-text {
		position: absolute;
		z-index: 2;
		color: $white;
		top: 30%;
		width: 100%;
	}
	.carousel-control-prev-icon, .carousel-control-next-icon {
		background: rgba(0, 0, 0, 0.3);
		background-image: none;
		width: 60px;
		height: 60px;
		font-size: $default-value-size * 25 !important;
		color: $white;
		line-height: 60px !important;
		border-radius: 50%;
		opacity: 0;
	}
	.carousel-control-next, .carousel-control-prev {
		width: 10%;
		z-index: 9;
	}
	&:hover {
		.carousel-control-prev-icon, .carousel-control-next-icon {
			opacity: 1;
		}
	}
}
/*-------- Slide Carousel-------*/