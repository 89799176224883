/*-------- Backgrounds -------*/
@each $colors, $value in $colors {
	@include bg-variant(".bg-#{$colors}", $value);
}

/*-------- Background transparents -------*/
.bg-success-transparent {
	background-color: rgba($color-success, 0.1) !important;
}
.bg-info-transparent {
	background-color: rgba($color-info, 0.1) !important;
}
.bg-warning-transparent {
	background-color: rgba($color-warning, 0.1) !important;
}
.bg-danger-transparent {
	background-color: rgba($color-danger, 0.1) !important;
}
.bg-pink-transparent {
	background-color: rgba($color-pink, 0.1) !important;
}
.bg-purple-transparent {
	background-color: rgba($color-purple, 0.1) !important;
}
.bg-dark-transparent {
	background-color: rgba($color-dark, 0.3) !important;
}
.bg-dark-transparent6 {
	background-color: rgba($color-dark-rgb, 0.6) !important;
}
.bg-white-transparent {
	background-color: rgba($white, 0.18) !important;
}
.bg-light-50 {
	background-color: $body-color !important;
}
.bg-light-30 {
	background-color: $custom-white !important;
}
.bg-white {
	background-color: $custom-white	 !important;
}
.bg-transparent {
	background-color: transparent !important;
}

.bg-light{
	background-color: var(--color-light) !important;
		&[href]{
			&:focus, &:hover {
				color:$white;
				text-decoration: none;
				background-color: var(--color-light) !important;
			}
		}
}
.bg-return::before{
	content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    inset-inline-start: 0;
    inset-inline-end: 0;
    display: block;
    top: 0;
	background-position-y: center !important;
   background: url("../images/banners/banner14.jpg");
}
.bg-return-img-2::before{
	content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    inset-inline-start: 0;
    inset-inline-end: 0;
    display: block;
    top: 0;
   background: url("../images/banners/banner11.jpg");
   background-size: cover;
    background-position: center;

}
// .text-dark{
// 	color: var(--text-color) !important;
// }

/*-------- Backgrounds -------*/

