/*-------- Date-Range Picker -------*/
.daterangepicker {
	position: absolute;
	color: inherit;
	background-color: $custom-white;
	border-radius: 4px;
	border: 1px solid $border-color;
	width: 278px;
	max-width: none;
	padding: 0;
	top: 100px;
	inset-inline-start: 20px;
	z-index: 3001;
	display: none;
	font-family: arial;
	font-size: $default-value-size * 15;
	line-height: 1em;
	box-shadow: 5px 4px 12px 4px rgba(79, 37, 225, 0.03);
	&:after {
		position: absolute;
		display: inline-block;
		content: "";
	}
	&:before {
		position: absolute;
		display: inline-block;
		content: "";
		top: -7px;
		border-inline-end: 7px solid transparent;
		border-inline-start: 7px solid transparent;
		border-bottom: 7px solid $border-color;
	}
	&:after {
		top: -6px;
		border-inline-end: 6px solid transparent;
		border-bottom: 6px solid $custom-white;
		border-inline-start: 6px solid transparent;
	}
	&.opensleft {
		&:before {
			inset-inline-end: 9px;
		}
		&:after {
			inset-inline-end: 10px;
		}
	}
	&.openscenter {
		&:after, &:before {
			inset-inline-start: 0;
			inset-inline-end: 0;
			width: 0;
			margin-inline-start: auto;
			margin-inline-end: auto;
		}
	}
	&.opensright {
		&:before {
			inset-inline-start: 9px;
		}
		&:after {
			inset-inline-start: 10px;
		}
	}
	&.drop-up {
		margin-block-start: -7px;
		&:before {
			top: initial;
			bottom: -7px;
			border-bottom: initial;
			border-top: 7px solid $border-color;
		}
		&:after {
			top: initial;
			bottom: -6px;
			border-bottom: initial;
			border-top: 6px solid $custom-white;
		}
	}
	&.single {
		.calendar, .daterangepicker .ranges {
			float: none;
		}
		.drp-selected {
			display: none;
		}
	}
	&.show-calendar {
		.calendar, .drp-buttons {
			display: block;
		}
	}
	&.auto-apply .drp-buttons {
		display: none;
	}
	.calendar {
		display: block;
		max-width: 270px;
		&.left {
			padding: 8px 0 8px 8px;
		}
		&.right {
			padding: 8px;
		}
		&.single .calendar-table {
			border: none;
		}
	}
	.calendar-table {
		.next span, .prev span {
			color: $custom-white;
			border: solid 1px $border-color;
			border-width: 0 2px 2px 0;
			border-radius: 0;
			display: inline-block;
			padding: 3px;
		}
		.next span {
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
		}
		.prev span {
			transform: rotate(135deg);
			-webkit-transform: rotate(135deg);
		}
		td, th {
			text-align: center;
			vertical-align: middle;
			min-width: 32px;
			width: 32px;
			height: 24px;
			line-height: 24px;
			font-size: $default-value-size * 12;
			border-radius: 4px;
			border: 1px solid transparent;
			white-space: nowrap;
			cursor: pointer;
		}
		border: 1px solid $border-color;
		border-radius: 4px;
		background-color: $custom-white;
		table {
			width: 100%;
			margin: 0;
			border-spacing: 0;
			border-collapse: collapse;
		}
	}
	td.available:hover, th.available:hover {
		background-color: $color-light;
		border-color: transparent;
		color: inherit;
	}
	td.week, th.week {
		font-size: 80%;
		color: #ccc;
	}
	td {
		&.off {
			background-color: $custom-white;
			border-color: transparent;
			color: #999;
			&.end-date, &.in-range, &.start-date {
				background-color: $custom-white;
				border-color: transparent;
				color: #999;
			}
		}
		&.in-range {
			background-color: $color-light;
			border-color: transparent;
			color: $custom-black;
			border-radius: 0;
		}
		&.start-date {
			border-radius: 4px 0 0 4px;
		}
		&.end-date {
			border-radius: 0 4px 4px 0;
		}
		&.start-date.end-date {
			border-radius: 4px;
		}
	}
	th.month {
		width: auto;
	}
	option.disabled, td.disabled {
		color: #999;
		cursor: not-allowed;
		text-decoration: line-through;
	}
}
.hljs-link {}
a {
	&:focus, &:hover {}
}
.daterangepicker {
	select {
		&.monthselect, &.yearselect {
			font-size: $default-value-size * 12;
			padding: 1px;
			height: auto;
			margin: 0;
			cursor: default;
		}
		&.monthselect {
			margin-inline-end: 2%;
			width: 56%;
		}
		&.yearselect {
			width: 40%;
		}
		&.ampmselect, &.hourselect, &.minuteselect, &.secondselect {
			width: 50px;
			margin: 0 auto;
			background: #eee;
			border: 1px solid #eee;
			padding: 2px;
			outline: 0;
			font-size: $default-value-size * 12;
		}
	}
	.calendar-time {
		text-align: center;
		margin: 4px auto 0;
		line-height: 30px;
		position: relative;
		select.disabled {
			color: #ccc;
			cursor: not-allowed;
		}
	}
	.drp-buttons {
		clear: both;
		text-align: endt;
		padding: 8px;
		border-top: 1px solid $border-color;
		display: none;
		line-height: 12px;
		vertical-align: middle;
	}
	.drp-selected {
		display: inline-block;
		font-size: $default-value-size * 12;
		padding-inline-end: 8px;
	}
	.drp-buttons .btn {
		margin-inline-start: 8px;
		font-size: $default-value-size * 12;
		font-weight: $font-weight-bold;
		padding: 4px 8px;
	}
	&.show-ranges .calendar.left {
		border-inline-start: 1px solid $border-color;
	}
	.ranges {
		float: none;
		text-align: start;
		margin: 0;
	}
	&.show-calendar .ranges {
		padding-block-start: 8px;
	}
	.ranges {
		ul {
			list-style: none;
			margin: 0 auto;
			padding: 0;
			width: 100%;
		}
		li {
			font-size: $default-value-size * 12;
			padding: 8px 12px;
			cursor: pointer;
			&:hover {
				background-color: #eee;
			}
			&.active {
				background-color: #08c;
				color: $white;
			}
		}
	}
}
@media (min-width: 564px) {
	.daterangepicker {
		&.ltr .calendar.right .calendar-table, &.rtl .calendar.left .calendar-table {
			border-inline-start: none;
			border-start-start-radius: 0;
			border-end-start-radius: 0;
		}
		&.ltr .calendar.left .calendar-table, &.rtl .calendar.right .calendar-table {
			border-inline-end: none;
			border-start-end-radius: 0;
			border-end-end-radius: 0;
		}
		width: 544px;
		inset-inline-end: 20px !important;
		.ranges ul {
			width: auto;
		}
		&.single {
			.ranges ul {
				width: 100%;
			}
			.calendar.left {
				clear: none;
			}
			&.ltr {
				.calendar, .ranges {
					float: #{$float-left};
				}
			}
			&.rtl {
				.calendar, .ranges {
					float: #{$float-right};
				}
			}
		}
		&.ltr {
			direction: ltr;
			text-align: start;
			.calendar {
				&.left {
					clear: left;
					margin-inline-end: 0;
					width: 50%;
					.calendar-table {
						padding-inline-end: 8px;
					}
				}
				&.right {
					margin-inline-start: 0;
					width: 50%;
				}
				float: #{$float-left};
			}
			.ranges {
				float: #{$float-left};
			}
		}
		&.rtl {
			direction: rtl;
			text-align: endt;
			.calendar {
				&.left {
					clear: right;
					margin-inline-start: 0;
					.calendar-table {
						padding-inline-start: 12px;
					}
				}
				&.right {
					margin-inline-end: 0;
				}
				text-align: endt;
				float: #{$float-right};
			}
			.ranges {
				text-align: endt;
				float: #{$float-right};
			}
		}
	}
}
@media (min-width: 730px) {
	.daterangepicker {
		.ranges {
			width: auto;
		}
		&.ltr .ranges {
			/* float: #{$float-left}; */
			/* border-inline-end: 1px solid $border-color; */
			width: 100%;
		}
		&.rtl .ranges {
			float: #{$float-right};
		}
		.calendar.left {
			clear: none !important;
		}
	}
}
.range_inputs {
	padding: 20px;
	border-top: 1px solid $border-color;
	width: 100%;
}
.daterangepicker_input {
	position: relative;
	i {
		position: absolute;
		top: 12px;
		inset-inline-end: 12px;
	}
}
@media (min-width: 564px) {
	.drp-calendar {
		&.left, &.right {
			width: 50%;
			float: #{$float-left};
			text-align: start;
		}
	}
}
/*-------- Date-Range Picker -------*/