/*-------- Tour Widgets -------*/
.tour-service2 {
	width: 150px;
	height: 150px;
	border-radius: 50% !important;
	padding-top: 23px !important;
	background: rgba(0, 0, 0, 0.1);
}
.place-tour {
	.btn {
		opacity: 0;
		bottom: 40px;
		transition: all 0.4s;
	}
	small {
		position: fixed;
		inset-inline-end: 50px;
		top: 50px;
	}
}
.place-tour-card2 .place-tour small {
	position: initial;
	display:block;
}
.place-tour {
	&:hover {
		.btn {
			display: inline-block;
			bottom: 45px;
			opacity: 1;
			transition: all 0.4s;
		}
		transition: all 0.4s;
		margin-top: 5px;
	}
	transition: all 0.4s;
	margin-top: 50px;
}
.place-tour-card2 {
	.place-tour {
		text-align: center;
		bottom: -30px;
		position: absolute;
		inset-inline-start: 0;
		inset-inline-end: 0;
	}
	&:hover .btn {
		display: inline-block;
		bottom: 45px;
		opacity: 1;
		transition: all 0.4s;
		margin-top: 25px;
	}
	.place-tour i {
		width: 25px;
		height: 25px;
		line-height: 25px;
		display: inline-block;
		background: rgba($white, 0.3);
		border-radius: 50%;
	}
	p {
		margin-bottom: 10px;
	}
	&:hover .place-tour {
		bottom: 20px;
	}
}
.place-tour-card {
	height: 350px;
}

/*--Tour Comparision---*/
.tour-comparision {
	position: relative;
	.card-image-difference {
		i {
			position: relative;
			z-index: 999;
			font-size: $default-value-size * 20;
			line-height: 50px;
		}
		width: 50px;
		height: 50px;
		border-radius: 50%;
		margin: 0 auto;
		line-height: 50px;
		text-align: center;
		position: absolute;
		top: 40%;
		inset-inline-start: 0;
		inset-inline-end: 0;
	}
}
.card-image-difference1 {
	inset-inline-end: -26px !important;
	inset-inline-start: auto !important;
	z-index: 9;
}
.compare-button {
	position: absolute;
	top: 0;
	bottom: 0;
	inset-inline-start: 0;
	inset-inline-end: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	display: block;
	margin: 0 auto;
	text-align: center;
	a {
		top: 40%;
		position: relative;
		opacity: 0;
		transition: all 0.5s;
	}
}
.tour-comparision:hover .compare-button a {
	opacity: 1;
	transition: all 0.5s;
}
.numeric-number {
	width: 25px;
	height: 25px;
	display: inline-block;
	background: $color-light;
	text-align: center;
	border-radius: 50%;
	line-height: 25px;
}
.ui-slider-horizontal {
	height: 5px !important;
}
.tour-service {
	text-align: center;
	margin: 0 auto;
	display: block;
	padding: 10px;
	border-radius: 4px;
	cursor: pointer;
	h6 {
		margin-bottom: 0;
		margin-top: 10px;
	}
	&:hover {
		background: rgba(0, 0, 0, 0.25);
		box-shadow: 5px 4px 12px 4px $color-primary2;
	}
}
.top-cities {
	background: $custom-white;
	border: 1px solid $border-color;
	padding: 20px;
	display: block;
	cursor: pointer;
	img {
		width: 50%;
		margin: 0 auto;
		padding: 10px;
		border-radius: 50%;
	}
}

/****Add Tour******/
.category-bus, .category-cruise, .category-car, .category-flight, .category-hotel, .category-tour {
	display: none;
}
.addtour .category-tour, .addhotel .category-hotel, .addflight .category-flight, .addcar .category-car, .addcruise .category-cruise, .addbus .category-bus {
	display: block;
}

/*-------- Tour Widgets -------*/
