/*-------- Buttons -------*/
.btn-option {
	background: 0 0;
	color: #a7b4c9;
	&:hover {
		color: #5f6877;
	}
	&:focus {
		box-shadow: none;
		color: #5f6877;
	}
}
.btn-list {
	margin-bottom: -.5rem;
	font-size: 0;
	> {
		.btn, .dropdown {
			margin-bottom: 0.5rem;
		}
		.btn:not(:last-child), .dropdown:not(:last-child) {
			margin-inline-end: 0.5rem;
		}
	}
}
.btn-loading {
	color: transparent !important;
	pointer-events: none;
	position: relative;
	&:after {
		content: '';
		-webkit-animation: loader .5s infinite linear;
		animation: loader .5s infinite linear;
		border: 2px solid $white;
		border-radius: 50%;
		border-right-color: transparent !important;
		border-top-color: transparent !important;
		display: block;
		height: 1.4em;
		width: 1.4em;
		position: absolute;
		inset-inline-start: calc(50% - (1.4em / 2));
		top: calc(50% - (1.4em / 2));
		-webkit-transform-origin: center;
		transform-origin: center;
		position: absolute !important;
	}
}
@-webkit-keyframes loader {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes loader {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.btn-group-sm>.btn-loading.btn:after {
	height: 1em;
	width: 1em;
	inset-inline-start: calc(50% - (1em / 2));
	top: calc(50% - (1em / 2));
}
.btn-loading {
	&.btn-sm:after {
		height: 1em;
		width: 1em;
		inset-inline-start: calc(50% - (1em / 2));
		top: calc(50% - (1em / 2));
	}
	&.btn-primary:after {
		border-color: $white;
	}
}
.btn-send {
	background: none repeat scroll 0 0 #00a8b3;
	color: $white;
	&:hover {
		background: none repeat scroll 0 0 #009da7;
	}
}
.btn-icon {
	padding-inline-start: .5rem;
	padding-inline-end: .5rem;
	text-align: center;
}
.btn.btn-app {
	position: relative;
	padding: 15px 5px;
	margin: 0 0 10px 10px;
	min-width: 80px;
	height: 68px;
	box-shadow: none;
	border-radius: 0;
	text-align: center;
	color: #a7b4c9;
	border: 1px solid $border-color;
	background-color: $color-light;
	font-size: $default-value-size * 12;
	> {
		.fa, .glyphicon, .ion {
			font-size: $default-value-size * 20;
			display: block;
		}
	}
	&:hover {
		background: $custom-white;
		color: #3f5068;
		border-color: $border-color;
	}
	&:active, &:focus {
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	}
	>.badge {
		position: absolute;
		top: -3px;
		inset-inline-end: -10px;
		font-weight: $font-weight-normal;
	}
}
.btn-white {
	color: $text-color;
	background-color: $custom-white;
	border-color: $border-color;
	&:hover {
		color: #3d4e67;
		background-color: #e9ecfb;
		border-color: #e9ecfb;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba($white, 0.5);
	}
	&.disabled, &:disabled {
		color: #3d4e67;
		background-color: $white;
		border-color: $white;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #3d4e67;
			background-color: #e9ecfb;
			border-color: #e9ecfb;
		}
	}
}
.show>.btn-white.dropdown-toggle {
	color: #3d4e67;
	background-color: #e9ecfb;
	border-color: #e9ecfb;
}
.btn-white:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba($white, 0.5);
	}
}
.show>.btn-white.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba($white, 0.5);
}
.btn-gray {
	color: $white;
	background-color: #868e96;
	border-color: #868e96;
	&:hover {
		color: $white;
		background-color: #727b84;
		border-color: #605e7e;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #868e96;
		border-color: #868e96;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: $white;
			background-color: #605e7e;
			border-color: #666e76;
		}
	}
}
.show>.btn-gray.dropdown-toggle {
	color: $white;
	background-color: #605e7e;
	border-color: #666e76;
}
.btn-gray:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
	}
}
.show>.btn-gray.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
}
.btn-light{
	color:$text-color !important
}

//btn-socail-colors
@each $socail-colors, $value in $socail-colors {
	@include btn-variant(".btn-#{$socail-colors}", $value);
}
/*-------- Buttons -------*/

