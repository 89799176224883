/*-------- Video -------*/
#index-video {
	.index-video-container {
		position: relative;
		overflow: hidden;
		&:after {
			bottom: 0;
			content: "";
			inset-inline-start: 0;
			opacity: .7;
			position: absolute;
			inset-inline-end: 0;
			top: 0;
			z-index: 1;
		}
		.videocaption {
			inset-inline-start: 1%;
			position: absolute;
			inset-inline-end: 1%;
			text-align: center;
			top: 30%;
			z-index: 2;
			h2 {
				color: $white;
				font-size: 4.5em;
				font-weight: 900;
				-webkit-box-sizing: content-box;
				-moz-box-sizing: content-box;
				box-sizing: content-box;
				border: none;
				color: rgba($white, 1);
				text-align: center;
				-o-text-overflow: clip;
				text-overflow: clip;
				text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba($text-color, 0.0980392), 0 0 5px rgba($text-color, 0.0980392), 0 1px 3px rgba($text-color, 0.298039), 0 3px 5px rgba($text-color, 0.2), 0 5px 10px rgba($text-color, 0.247059), 0 10px 10px rgba($text-color, 0.2), 0 20px 20px rgba($text-color, 0.14902);
				-webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-moz-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
			}
			h4 {
				color: $white;
				font-size: 2em;
				margin-block-start: 25px;
			}
			.btn-slide {
				background: #f62459;
				color: $white;
				font-size: $default-value-size * 16;
				font-weight: $font-weight-normal;
				margin: 25px auto 10px auto;
				padding: 20px 50px;
				border-radius: 0;
				text-transform: uppercase;
				&:hover {
					background: $white;
					color: $text-color;
				}
			}
		}
	}
	video {
		width: 100%;
		background-size: cover;
		position: absolute;
		top: 0;
		inset-inline-start: 0;
	}
}
.video-list-thumbs {
	>li {
		margin-block-end: 12px;
		>a {
			display: block;
			position: relative;
			color: #6b6f80;
			border-radius: 5px;
			transition: all .5s ease-in-out;
			border-radius: 4px;
		}
	}
	h2 {
		bottom: 0;
		font-size: $default-value-size * 14;
		height: 33px;
		margin: 8px 0 0;
	}
	span {
		font-size: $default-value-size * 50;
		opacity: .8;
		position: absolute;
		inset-inline-end: 0;
		inset-inline-start: 0;
		top: 42%;
		margin: 0 auto;
		text-align: center;
		transition: all .5s ease-in-out;
		z-index: 5;
	}
	>li>a:hover .fa {
		color: $white;
		opacity: 1;
		text-shadow: 0 1px 3px rgba($text-color, 0.8);
	}
	.duration {
		background-color: $color-dark-purple;
		border-radius: 0;
		color: $white;
		font-size: $default-value-size * 13;
		font-weight: $font-weight-bold;
		inset-inline-start: 12px;
		line-height: 9px;
		padding: 5px 8px;
		position: absolute;
		bottom: 12px;
		transition: all .5s ease;
	}
	>li>a:hover .duration {
		background-color: $black;
	}
}
.card-video {
	background: url(../images/products/b5.png);
	background-size: cover;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba($text-color, 0.6);
		width: 100%;
		height: 100%;
		inset-inline-end: 0;
		top: 0;
	}
}
.item-video {
	a {
		display: inline-block;
		width: 2rem;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2rem;
		background: $white;
		color: $text-color;
		font-size: $default-value-size * 25;
		align-items: center;
		margin: 0 auto;
		justify-content: center;
	}
	i {
		margin-inline-start: 5px;
	}
}

.video-btn {
	box-shadow: 0 0 0 rgba($white, 0.9);
	//animation: pulse 2s infinite;
	animation-duration: .9s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-out;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	line-height: 100px;
	font-size: $default-value-size * 30;
	background: #fdfdfd1a;
	border: 6px solid rgba($white, 0.05);
	i {
		margin: 0 0 0 7px;
	}
}
.video-btn1 span {
	box-shadow: 0 0 0 rgba($white, 0.9);
	border-radius: 50%;
	width: 60px;
	height: 60px;
	line-height: 60px;
	font-size: $default-value-size * 24;
	background: rgba($color-dark-purple , 0.5);
	margin: 0 auto;
	text-align: center;
	top: 40%;
	position: absolute;
	inset-inline-start: 0;
	inset-inline-end: 0;
}

#homeVideo button.btn.btn-default {
	background: rgba($white, 0.5);
	position: absolute;
	inset-inline-end: 5px;
	top: 5px;
	padding: 0;
	line-height: 32px;
	z-index: 5;
	color: $white;
	width: 30px;
	height: 30px;
	min-width: auto;
	border: 0;
	outline: 0;
	&:hover {
		background: rgba($white, 0.5);
		color: $white;
		border: 0;
		outline: 0;
	}
}
.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
	&::before {
		display: block;
		content: "";
	}
	.embed-responsive-item, embed, iframe, object, video {
		position: absolute;
		top: 0;
		bottom: 0;
		inset-inline-start: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}
.embed-responsive-21by9::before {
	padding-block-start: 42.85714286%;
}
.embed-responsive-16by9::before {
	padding-block-start: 56.25%;
}
.embed-responsive-4by3::before {
	padding-block-start: 75%;
}
.embed-responsive-1by1::before {
	padding-block-start: 100%;
}
/*-------- Video -------*/