/*------ Timepicker -------*/
.ui-timepicker-wrapper {
	overflow-y: auto;
	max-height: 150px;
	width: 10em;
	background: $custom-white;
	border: 1px solid $border-color;
	-webkit-box-shadow: 5px 4px 12px 4px rgba(79, 37, 225, 0.03);
	-moz-box-shadow: 5px 4px 12px 4px rgba(79, 37, 225, 0.03);
	box-shadow: 5px 4px 12px 4px rgba(79, 37, 225, 0.03);
	outline: none;
	z-index: 10001;
	margin: 0;
	border-radius: 0 0 5px 5px;
	&.ui-timepicker-with-duration {
		width: 13em;
		&.ui-timepicker-step-30, &.ui-timepicker-step-60 {
			width: 11em;
		}
	}
}
.ui-timepicker-list {
	margin: 0;
	padding: 0;
	list-style: none;
}
.ui-timepicker-duration {
	margin-inline-start: 5px;
}
.ui-timepicker-list {
	li {
		padding: 0 15px;
		cursor: pointer;
		white-space: nowrap;
		list-style: none;
		margin: 0;
		height: 35px;
		line-height: 35px;
		font-size: $default-value-size * 12;
	}
	&:hover .ui-timepicker-selected {
		background: $white;
	}
}
li.ui-timepicker-selected .ui-timepicker-duration {
	color: #ccc;
}
.ui-timepicker-list li {
	&:hover .ui-timepicker-duration {
		color: #ccc;
	}
	&.ui-timepicker-disabled {
		color: #888;
		cursor: default;
		&:hover {
			color: #888;
			cursor: default;
		}
	}
	&.ui-timepicker-selected.ui-timepicker-disabled {
		color: #888;
		cursor: default;
	}
	&.ui-timepicker-disabled:hover, &.ui-timepicker-selected.ui-timepicker-disabled {
		background: #f2f2f2;
	}
}
/*------ Timepicker -------*/
