/*------ media -------*/
.mediaicon {
	font-size: $default-value-size * 18;
	color: $white;
	width: 40px;
	height: 40px;
	text-align: center;
	padding: 5px 0;
	border-radius: 20px;
}
.media-body {
	h4 {
		font-weight: $font-weight-semibold;
		font-size: $default-value-size * 16;
	}
	.card-item-desc a {
		color: $color-muted;
	}
}
.mediafont {
	font-size: $default-value-size * 16;
	font-weight: $font-weight-semibold2;
}

.media-list .media-icons {
	font-size: $default-value-size * 18;
	color: $black;
}

.media-icon {
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 100%;
}
.media-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.media .chat {
	i, a {
		font-size: $default-value-size * 13;
	}
}

.media-posts {
	width: 100%;
	height: 233px;
	overflow: hidden;
	&:hover {
		overflow: scroll;
	}
}
/*------ media -------*/