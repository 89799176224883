/*-------- DropDown -------*/
.dropdown, .dropleft, .dropright, .dropup {
	position: relative;
}
.dropdown-menu {
	position: absolute;
	top: 100%;
	inset-inline-start: 0;
	z-index: 1000;
	display: none;
	float: #{$float-left};
	min-width: 10rem;
	padding: .5rem 0;
	margin: .125rem 0 0;
	font-size: .9375rem;
	color: #3d4e67;
	text-align: left;
	list-style: none;
	background-color: $custom-white;
	background-clip: padding-box;
	border: 1px solid $border-color;
	border-radius: $default-value-size * 5;
	box-shadow: 0 1px 2px 0 rgba($text-color, 0.05);
	min-width: 12rem;
	>li>a {
		display: block;
		padding: 6px 20px;
		clear: both;
		font-weight: $font-weight-normal;
		line-height: 1.428571429;
		color: $text-color;
		white-space: nowrap;
		&:focus, &:hover {
			text-decoration: none;
			background-color: $body-color;
		}
	}
	.divider {
		height: 1px;
		margin: 9px 0;
		overflow: hidden;
		background-color: $border-color;
	}
	.dropdown-plus-title {
		width: 100% !important;
		color: #cecde0 !important;
		padding: 6px 12px !important;
		font-weight: $font-weight-semibold  ;
		border: 0 solid $border-color !important;
		border-bottom-width: 1px !important;
		cursor: pointer !important;
	}
}
ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
	padding-block-start: 10px !important;
	padding-block-end: 10px !important;
	line-height: 20px !important;
}
.dropdown-menu-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 15px;
	border-bottom: 1px solid $border-color;
}
.dropdown-menu-header label {
	margin-block-end: 0;
	font-size: $default-value-size * 14;
	font-weight: $font-weight-semibold2;
	text-transform: uppercase;
	color: #cecde0;
}
%dropdown-style{
	display: inline-block;
	width: 0;
	height: 0;
	margin-inline-start: .255em;
	vertical-align: .255em;
	content: "";
}
%empty-after{
	&:empty::after {
		margin-inline-start: 0;
	}
}
%dropdown-arrow{
	position: absolute;
	top: -9px;
	inset-inline-start: 12px;
	display: inline-block;
	border-inline-end: 9px solid transparent;
	border-inline-start: 9px solid transparent;		
	border-bottom: 9px solid $border-color;
	content: '';
}
.dropdown-toggle {
	&::after {
		@extend %dropdown-style;
		border-top: .3em solid;
		border-inline-end: .3em solid transparent;
		border-bottom: 0;
		border-inline-start: .3em solid transparent;
	}
	@extend %empty-after;
}
.dropdown-menu-right {
	inset-inline-end: 0;
	inset-inline-start: auto;
}
.dropup {
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-block-start: 0;
		margin-block-end: .125rem;
	}
	.dropdown-toggle {
		&::after {
			@extend %dropdown-style;
			border-top: 0;
			border-inline-end: .3em solid transparent;
			border-bottom: .3em solid;
			border-inline-start: .3em solid transparent;
		}
		@extend %empty-after;
	}
}
.dropright {
	.dropdown-menu {
		top: 0;
		inset-inline-end: auto;
		inset-inline-start: 100%;
		margin-block-start: 0;
		margin-inline-start: .125rem;
	}
	.dropdown-toggle {
		&::after {
			@extend %dropdown-style;
			border-top: .3em solid transparent;
			border-inline-end: 0;
			border-bottom: .3em solid transparent;
			border-inline-start: .3em solid;
			vertical-align: 0;
		}
		@extend %empty-after;
	}
}
.dropleft {
	.dropdown-menu {
		top: 0;
		inset-inline-end: 100%;
		inset-inline-start: auto;
		margin-block-start: 0;
		margin-inline-end: .125rem;
	}
	.dropdown-toggle {
		&::after {
			@extend %dropdown-style;
			display: none;
		}
		&::before {
			@extend %dropdown-style;
			border-top: .3em solid transparent;
			border-inline-end: .3em solid;
			border-bottom: .3em solid transparent;
			vertical-align: 0;
		}
		@extend %empty-after;
	}
}
.dropdown-menu {
	&[x-placement^=bottom], &[x-placement^=left], &[x-placement^=right], &[x-placement^=top] {
		inset-inline-end: auto;
		bottom: auto;
	}
}
.dropdown-divider {
	height: 0;
	margin: .5rem 0;
	overflow: hidden;
	border-top: 1px solid $border-color;
}
.dropdown-item {
	display: block;
	width: 100%;
	padding: .5rem 1.5rem;
	clear: both;
	font-weight: $font-weight-normal;
	color: $text-color;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	&:focus, &:hover, &.active, &:active {
		text-decoration: none;
		background-color: $border-color;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: transparent;
	}
	&.user{
		font-size: $default-value-size * 16;
		padding: 5px;
	}
}
.dropdown-menu.show {
	display: block;
	box-shadow: 0 5px 25px rgba(39, 55, 76, 0.2);
	font-size: $default-value-size * 13;	
}
.dropdown-header {
	display: block;
	padding: .5rem 1.5rem;
	margin-block-end: 0;
	font-size: .875rem;
	color: #868e96;
	white-space: nowrap;
}
ul.dropdown-menu li.dropdown ul.dropdown-menu {
	background-color: #f4f4f4 !important;
	background-color: $white!important;
	border: 0 solid #4285f4 !important;
	box-shadow: 0 0 3px rgba(25, 25, 25, 0.3) !important;
	top: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
	position: absolute !important;
	width: 100% !important;
}
.dropdown-list-footer {
	padding: 10px 15px;
	text-align: center;
	font-size: $default-value-size * 12;
	border-top: 1px solid $border-color;
}
.dropdown-media-list {
	padding: 0;
}
.dropdown-menu-arrow {
	&:before {
		@extend %dropdown-arrow;
		border-bottom-color: $border-color;		
	}
	&:after {
		@extend %dropdown-arrow;
		border-bottom: 9px solid $border-color;
	}
	&.dropdown-menu-right {
		&:after, &:before {
			inset-inline-start: auto;
			inset-inline-end: 12px;
		}
	}
}
.dropdown-toggle {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	&:after {
		vertical-align: .155em;
	}
	&:empty:after {
		margin-inline-start: 0;
	}
}
.dropdown-icon {
	margin-inline-end: .5rem;
	margin-inline-start: -.5rem;
	width: 1em;
	display: inline-block;
	text-align: center;
	vertical-align: -1px;
	font-size: $default-value-size * 14;
	font-weight: $font-weight-semibold2;
}
/*-------- DropDown -------*/
