/*---- Custom Select -----*/

.custom-select {
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	padding: .5rem 1.75rem .5rem .75rem;
	line-height: 1.5;
	color: #cecde0;
	vertical-align: middle;
	background: $custom-white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
	background-size: 8px 10px;
	border: 1px solid $border-color;
	border-radius: 5px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		outline: 0;
		box-shadow: inset 0 1px 2px rgba($text-color, 0.075), 0 0 5px rgba(25, 145, 235, 0.5);
		&::-ms-value {
			color: #3d4e67;
			background-color: $white;
		}
	}
	&[multiple], &[size]:not([size="1"]) {
		height: auto;
		padding-inline-end: .75rem;
		background-image: none;
	}
	&:disabled {
		color: #868e96;
		background-color: $border-color;
	}
	&::-ms-expand {
		opacity: 0;
	}
}
.custom-select-sm {
	height: calc(1.8125rem + 2px);
	padding-top: .5rem;
	padding-bottom: .5rem;
	font-size: 75%;
}
.custom-select-lg {
	height: calc(2.6875rem + 2px);
	padding-top: .5rem;
	padding-bottom: .5rem;
	font-size: 125%;
}
.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	margin-bottom: 0;
}
.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 2.375rem;
	margin: 0;
	opacity: 0;
	&:lang(en)~.custom-file-label::after {
		content: "Browse";
	}
}
.custom-file-label {
	position: absolute;
	top: 0;
	inset-inline-end: 0;
	inset-inline-start: 0;
	z-index: 1;
	height: 2.375rem;
	padding: .375rem .75rem;
	line-height: 1.8;
	color: #3d4e67;
	background-color: $custom-white;
	border: 1px solid $border-color;
	border-radius: 5px;
	&::after {
		position: absolute;
		top: 0;
		inset-inline-end: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		height: calc(2.375rem - 1px * 2);
		padding: .375rem .75rem;
		line-height: 1.5;
		color: $white;
		content: "Browse";
		border-radius: 0 3px 3px 0;
	}
}
/*---- Custom Select -----*/