/*------ Gallery -------*/
.img-gallery {
	margin-inline-end: -.25rem;
	margin-inline-start: -.25rem;
	margin-block-end: -.5rem;
	> {
		.col, [class*=col-] {
			padding-inline-start: .25rem;
			padding-inline-end: .25rem;
			padding-block-end: .5rem;
		}
	}
}

.gallery-close-button {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background:$white;
	position: absolute;
	display: block;
	inset-inline-end: -15px;
	top: -15px;
	z-index: 9;
	line-height: 53px;
	cursor: pointer;
	i {
		margin: 10px;
	}
}
.gallery-item {
	cursor: pointer;
	display: block;
}
/*------ Gallery -------*/