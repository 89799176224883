//Bootstrap root

:root {

  @each $color,
  $value in $colors {
    --#{$color}: #{$value};
  }

  --float-left:left;
  --float-right:right;

  @each $color,
  $value in $hovercolors {
    --#{$color}: #{$value};
  }

  //default
  --body-color          : #f5f4fb;
  --text-color          : #504e70;
  --border-color        : #e5e4f0;
  --border-dark-color   : #efecfb;
  --form-background     : #f8f7fc;
  --white               : #fff;
  --black               : #20242c;
  --custom-white        : #fff;
  --custom-black        : #20242c;
  --color-light         : #f5f4fb;
  --white-rgb           : 255,255,255;
  --black-rgb           : 0,0,0;
  --color-dark			    : #312d65;
  --color-dark-rgb			: 49, 45, 101;
  --color-muted		      :  #797896;
  
// Theme 01
--primary-rgb:113, 103, 255;
--primary-bg-color: rgb(var(--primary-rgb));
--primary-bg-hover: rgb(var(--primary-rgb), 0.85);
--primary-bg-border: rgb(var(--primary-rgb));
--color-primary-1: rgba(var(--primary-rgb), 0.1);
--color-primary-2: rgba(var(--primary-rgb), 0.2);
--color-primary-3: rgba(var(--primary-rgb), 0.3);
--color-primary-4: rgba(var(--primary-rgb), 0.4);
--color-primary-5: rgba(var(--primary-rgb), 0.5);
--color-primary-6: rgba(var(--primary-rgb), 0.6);
--color-primary-7: rgba(var(--primary-rgb), 0.7);
--color-primary-8: rgba(var(--primary-rgb), 0.8);
--color-primary-9: rgba(var(--primary-rgb), 0.9);
--color-primary-05: rgba(var(--primary-rgb), 0.05);


}
.dark-theme {
   --body-color     : #292b38;
   --text-color     : #d2d2d5;
   --border-color   : rgb(255, 255, 255, 0.1);
   --white          : #fff;
   --black          : #20242c;
   --custom-white   : #20242c;
   --custom-black   : #fff;
   --color-light    : rgba(0, 0, 0, 0.2);
   --color-dark			: #fff;
   --color-muted		: rgba(255, 255, 255, 0.6);
}


// To activate color theme  

// @media (prefers-color-scheme:light) {
//   :root {

//     @each $color,
//     $value in $colors {
//       --#{$color}: #{$value};
//     }

//     --float-left:left;
//     --float-right:right;

//     @each $color,
//     $value in $hovercolors {
//       --#{$color}: #{$value};
//     }

//     //default
//     --body-color : #f5f4fb;
//     --text-color : #312d65;
//     --border-color : #e5e4f0;
//     --border-dark-color : #efecfb;
//     --form-background : #f8f7fc;
//     --white : #fff;
//     --black : #20242c;
//     --custom-white: #fff;
//     --custom-black: #20242c;

//   }
// }

// @media (prefers-color-scheme:dark) {
//  :root {
//   @each $color,
//     $value in $colors {
//       --#{$color}: #{$value};
//     }

//     --float-left:left;
//     --float-right:right;

//     @each $color,
//     $value in $hovercolors {
//       --#{$color}: #{$value};
//     }
//     --body-color: #212332;
//     --text-color: #ffffff;
//     --border-color: rgba(255, 255, 255, 0.1);
//     --white: #20242c;
//     --black: #fff;
//     --custom-white: #20242c;
//     --custom-black: #fff;
//   }

// }