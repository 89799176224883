/*-------- Blog Styles -------*/
.card-blogs .card {
	border: none;
	box-shadow: none;
}
.card-blogs1 {
	.card {
		box-shadow: none;
		border-bottom: 1px solid $border-color;
		padding-bottom: 15px;
		margin-bottom: 10px;
		&:last-child {
			box-shadow: none;
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
	.card-item-desc .card-item-price {
		.newprice {
			font-weight: $font-weight-semibold2;
		}
		.oldprice {
			font-size: $default-value-size * 14;
		}
	}
}
.card-blogs2 .card {
	border: none;
	box-shadow: none;
}
.blog-list {
	.item7-card-img, .item-card2-img img {
		height: 196px !important;
	}
}
.blog-list-1 {
	.item7-card-img, .item-card2-img img {
		height: 196px !important;
	}
}
.blog-list-1 {
	height: 196px;
}
.card-blog-overlay6 i {
	font-size: 3rem;
}
.blog-relative {
	position: relative;
}
.blog--category {
	position: absolute;
	bottom: 10px;
	padding: 3px 20px;
	inset-inline-start: 10px;
	color: #4f4b8b;
	border-radius: 3px;
	font-weight: $font-weight-bold;
	opacity: 1;
	text-transform: capitalize;
	transition: all .5s ease 0s;
	z-index: 9;
	background: $white!important;
}
.card:hover .blog--category {
	opacity: 1;
	inset-inline-start: 10px;
	transition: all .5s ease 0s;
}
.place-blog {
	bottom: 0px;
	position: absolute !important;
}
.date-blog {
	position: absolute;
	padding: 6px 10px;
	border-radius: 3px;
	bottom: 15px;
	inset-inline-end: 15px;
	color: $white;
	text-align: center;
}
.blog-details-absolute {
	position: absolute;
	bottom: 0;
	padding: 10px 15px;
	color: $white;
	width: 100%;
	background: rgba($black-rgb, 0.5) !important;
	a {
		position: inherit !important;
		inset-inline-start: inherit !important;
		top: inherit !important;
		bottom: auto !important;
	}
}
.items-gallery .items-blog-tab-heading {
	text-align: center;
	border-radius: 50px;
	margin: 0 auto;
	display: inline-table;
	padding: 15px;
	margin-bottom: 30px;
}
.items-blog-tab-heading .items-blog-tab-menu li a {
	padding: 7px 20px 7px 20px;
	color: $text-color;
	background: $custom-white;
	margin: 0 10px;
	border-radius: 5px;
	display: block;
	border: 1px solid $border-color;
	box-shadow: 0px 0px 12px 0px rgba(79, 37, 225, 0.03);
}

.card-blog-overlay6 {
	background: url(../images/banners/subscribe.jpg);
	background-size: cover;
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(107, 34, 251, 0.6);
		width: 100%;
		height: 100%;
		inset-inline-end: 0;
		top: 0;
	}
}
.card-blog-img {
	background: url(../images/banners/tour.png);
	background-size: cover;
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		inset-inline-end: 0;
		top: 0;
	}
}
.blogimg {
	width: 100px;
}
.post-content {
	&.active {
		display: block;
	}
	display: none;
}
/*-------- Blog Styles -------*/